import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import LPMImage from '../images/LPM-Image-1.svg'
import LPMImage2 from '../images/LPM-Image-2.png'
import LPMImage3 from '../images/LPM-Image-3.png'
import LPMImage4 from '../images/LPM-Image-4.png'
import Accordion from '../components/Accordion'


const Button = styled.button`
  color: white;
  background-color: #0077AF;
  font-weight:500;
  height: 40px;
  width: 160px; 
  border: 1px solid transparent; 
  font-Size: 14px;
  align-self: center;
`
const WhiteButton = styled.button`
  color: #0077AF;
  background-color: white;
  font-weight:500;
  height: 40px;
  width: 160px; 
  border: 1px solid transparent; 
  border-radius: 1.5px;
  font-size: 16px;
  align-self: center;
`

const LegalProjectManagementPage = ({ location }) => {

    return (
        <Layout path={`${location.pathname}`} >
            <SEO title="Legal Project Management" />
            <section style={{ "height": "280px", "backgroundColor": "white", "padding": "104px 80px 0px 80px", "display": "grid", "gridTemplateColumns": "1fr 1fr", width: "1024px", "margin": "0 auto" }}>
                <div>
                    <h1 style={{ marginBottom: "16px", fontSize: "32px", paddingTop: "16px" }}>Legal Project Management</h1>
                    <p style={{ width: "434px", marginBottom: "32px", fontSize: "18px", lineHeight: "25px" }}>Request a project manager to help you find the perfect attorney so you can use your time doing things you like</p>
                    <Button id="on-hover-btn" style={{ width: "190px", borderRadius:"1.5px"}}>Request access</Button>
                </div>
                <div>
                    <img src={LPMImage} />
                </div>
            </section>
            <div style={{ backgroundColor: "#FBFBFB" }}>


                <section style={{ display: "grid", padding: "78px 80px 0px", gridTemplateColumns: "1fr 1fr", backgroundColor: "#FBFBFB", width: "1024px", "margin": "0 auto", alignItems: "center", justifyItems: "center" }}>
                    <div>
                        <h1 style={{ fontSize: "24px", marginBottom: "32px" }}>Advantages of using our legal project management services</h1>
                        <h2 style={{ width: "478px", fontSize: "20px", fontWeight: "500" }}>Increased Transparency and Improvement in Legal Services Rendered</h2>
                        <p style={{ width: "528px", fontSize: "16px", lineHeight: "25px" }}>When firms and their attorneys communicate better, many improvements occur such as a better understanding of what clients need and what attorneys deliver. Reducing and eliminating any misunderstandings between the two groups helps clients to get delivery of their much needed services faster and without the need to review or petition for reduced costs with attorneys or aw firms down he line For many international firims assistive services in this area help to curtail reducn</p>

                    </div>
                    <div>
                        <img src={LPMImage2} />
                    </div>
                </section>
            </div>
            <div style={{ backgroundColor: "#FBFBFB" }}>
                <section style={{ display: "grid", padding: "78px 80px 0px", gridTemplateColumns: "1fr 1fr", backgroundColor: "#FBFBFB", justifyItems: "center", alignItems: "center", width: "1024px", "margin": "0 auto" }}>
                    <div>
                        <img src={LPMImage3} />
                    </div>
                    <div>
                        <h2 style={{ width: "456px", fontSize: "20px", fontWeight: "500" }}>Better Understanding of Legal Budgets and Control of Project Costs</h2>
                        <p style={{ width: "456px", fontSize: "16px", lineHeight: "25px" }}>Utilizing Legal Project Management, firms can better understand what they need, how to achieve their goals, and a better understanding of the costs involved. Better understanding and preparation for such legal needs and costs helps to prevent unknown costs and over budgeting.</p>
                    </div>
                </section>
            </div>
            <div style={{ backgroundColor: "#FBFBFB" }}>


                <section style={{ display: "grid", padding: "78px 80px 0px", gridTemplateColumns: "1fr 1fr", backgroundColor: "#FBFBFB", justifyItems: "center", alignItems: "center", width: "1024px", "margin": "0 auto", paddingBottom: "101px" }}>
                    <div>
                        <h2 style={{ width: "483px", fontSize: "20px", fontWeight: "500" }}>Increased Trust, Resulting in Better Performance, Reliability, and Growth</h2>
                        <p style={{ width: "504px", fontSize: "16px", lineHeight: "25px" }}>Management teams work hard to continue their firm’s growth and make sure that no interruptions occur during these critical times. When legal needs, both procedural, and or relating to potential litigation occur, management teams move fast to figure the issues out. Juristerra’s Legal Project Managers assist these teams, regardless of size, to quickly communicate their needs and make sure no billing or legal mishaps occur that might hinder their projects.</p>
                    </div>
                    <div>
                        <img src={LPMImage4} />
                    </div>
                </section>
            </div>

            <section style={{ "backgroundColor": "#378CB3", height: "216px", textAlign: "center", display: "grid" }}>
                <div style={{ alignSelf: "center", justifySelf: "center", width: "588px" }}>
                    <h1 style={{ "color": "white", fontSize: "24px", fontWeight: "500", width: "580px", marginBottom: "20px" }}>Be one of the few to start using our platform before anyone else!</h1>
                    <WhiteButton id="whitebutton-hover">Join for free</WhiteButton>
                </div>

            </section>

            <section style={{ padding: "45px 70px 60px 70px", width: "1024px", "margin": "0 auto" }}>
                <h1 style={{ fontWeight: "500", paddingTop:"32px" }}>How it Works</h1>
                <ul style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", listStyle: "none", padding: "0px", justifyItems: "center" }}>
                    <li style={{ width: "180px" }}>
                        <h1 style={{ fontSize: "75px", color: "#0077AF", fontWeight: "700" }}>1</h1>
                        <h2 style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Request project manager</h2>
                        <p style={{ fontSize: "16px", marginTop: "8px" }} >You submit a request via our website.</p>
                    </li>
                    <li style={{ width: "180px" }}>
                        <h1 style={{ fontSize: "75px", color: "#0077AF", fontWeight: "700", opacity: "80%" }}>2</h1>
                        <h2 style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>We contact you</h2>
                        <p style={{ fontSize: "16px", marginTop: "8px" }}>We contact you to discuss your needs.</p>
                    </li>
                    <li style={{ width: "180px" }}>
                        <h1 style={{ fontSize: "75px", color: "#0077AF", fontWeight: "700", opacity: "70%" }}>3</h1>
                        <h2 style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>We search our network</h2>
                        <p style={{ fontSize: "16px", marginTop: "8px" }}>We help find you an attorney from our networks. We then help guide and connect everyone together.</p>
                    </li>
                    <li style={{ width: "180px" }}>
                        <h1 style={{ fontSize: "75px", color: "#0077AF", fontWeight: "700", opacity: "60%", marginTop: "25px" }}>4</h1>
                        <h2 style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Find best match</h2>
                        <p style={{ fontSize: "16px", marginTop: "8px" }}>Once you approve the fees, we start working on your project with the attorney.</p>
                    </li>
                    <li style={{ width: "180px" }}>
                        <h1 style={{ fontSize: "75px", color: "#0077AF", fontWeight: "700", opacity: "50%", marginTop: "25px" }}>5</h1>
                        <h2 style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Determine fees</h2>
                        <p style={{ fontSize: "16px", marginTop: "8px" }}>Once sections of the project is completed, attorneys will upload an invoice. Simply make payments using our secure payments portal.</p>
                    </li>
                    <li style={{ width: "180px" }}>
                        <h1 style={{ fontSize: "75px", color: "#0077AF", fontWeight: "700", opacity: "40%", marginTop: "25px" }}>6</h1>
                        <h2 style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Work on your projects</h2>
                        <p style={{ fontSize: "16px", marginTop: "8px" }}>We complete project to your satisfaction.</p>
                    </li>
                </ul>

            </section>

            <div style={{ backgroundColor: "#fbfbfb" }}>
                <section style={{ backgroundColor: "#FBFBFB", width: "1024px", "margin": "0 auto", "paddingBottom": "42px","marginTop": "40px" }}>
                    <div style={{ textAlign: "center" }}>
                        <h1 style={{
                            paddingTop: "40px", 
                            paddingBottom: "40px",
                            height: "32px",
                            fontFamily: "Yantramanav",
                            fontSize: "24px",
                            fontWeight: "500",
                            fontStretch: "normal",
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',

                            color: '#121212'
                        }}>Frequently asked questions</h1>

                    </div>
                    <Accordion style={{ backgroundColor: "white" }}
                        title="What is a legal project manager?" 
                        content="A legal project manager (LPM) helps clients with finding attorneys, guiding them with cases, finalizing all case prices and much more"
                    />
                    <Accordion style={{ backgroundColor: "white" }}
                        title="Is there a fee for requesting a legal project manager?"
                        content="Yes, there is a fee. To understand more about our plan, please contact us at contact@juristerra.net"
                    />     
                     <Accordion style={{ backgroundColor: "white" }}
                        title="Can I request a legal project manager anytime?"
                        content="Yes, you can request a Legal Project Manager anytime you want that fits into your timeline."
                    />                    
                    <div style={{ "marginTop": "50px", textAlign: "center" }}>
                        <p style={{
                            fontFamily: "Yantramanav",
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingBottom: "50px"
                        }}>Have more unanswered questions? Feel free to contact us via email at <a href="mailto:contact@juristerra.net" style={{ color: "#3eb0b4", textDecoration: "none" }}>contact@juristerra.net </a>or call us at <a href="tel:+11231231232" style={{ color: "#3eb0b4", textDecoration: "none" }}>(123) 123-1232</a></p>
                    </div>
                </section>
            </div>
        </Layout>

    );

}


export default LegalProjectManagementPage